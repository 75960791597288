var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-3 pt-5"},[_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","to":("/veranstaltung/" + (_vm.$route.params.veranstaltung)),"exact":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1)],1),_c('v-col',[_c('h2',{staticClass:"white--text"},[_vm._v("Veranstaltung bearbeiten")])])],1),(_vm.veranstaltung.data)?_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-xl py-3",attrs:{"dark":!_vm.template.light,"light":_vm.template.light}},[(_vm.veranstaltung.data.repeat)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.update_serie
                  ? 'Serientermin bearbeiten'
                  : 'Einzeltermin bearbeiten'},model:{value:(_vm.update_serie),callback:function ($$v) {_vm.update_serie=$$v},expression:"update_serie"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[(!_vm.update_serie)?_c('v-select',{attrs:{"filled":"","rounded":"","items":_vm.arten,"label":"Art der Veranstaltung"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.veranstaltung.data.art),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data, "art", $$v)},expression:"veranstaltung.data.art"}}):_vm._e(),(_vm.user.data.sportstaetten)?_c('v-select',{attrs:{"items":_vm.user.data.sportstaetten,"item-text":"name","item-value":"name","filled":"","rounded":"","label":"Sportstätte"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.sportstaette),callback:function ($$v) {_vm.sportstaette=$$v},expression:"sportstaette"}}):_vm._e(),(
                _vm.veranstaltung.data.art == 'Wettkampf' ||
                _vm.veranstaltung.data.art == 'Versammlung'
              )?_c('v-text-field',{attrs:{"filled":"","rounded":"","label":"Name der Veranstaltung"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.veranstaltung.data.name),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data, "name", $$v)},expression:"veranstaltung.data.name"}}):_vm._e(),(_vm.veranstaltung.data.art != 'Wettkampf' && !_vm.update_serie)?_c('v-select',{attrs:{"items":_vm.teams,"item-text":"name","item-value":"name","filled":"","rounded":"","label":_vm.veranstaltung.data.art == 'Meisterschaftsspiel' ||
                _vm.veranstaltung.data.art == 'Freundschaftsspiel' ||
                _vm.veranstaltung.data.art == 'Pokalspiel'
                  ? 'Heimmannschaft'
                  : 'Mannschaft'},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.veranstaltung.data.mannschaft),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data, "mannschaft", $$v)},expression:"veranstaltung.data.mannschaft"}}):_vm._e(),(
                _vm.veranstaltung.data.art == 'Meisterschaftsspiel' ||
                _vm.veranstaltung.data.art == 'Freundschaftsspiel' ||
                _vm.veranstaltung.data.art == 'Pokalspiel' ||
                _vm.veranstaltung.data.art == 'Auswärtsspiel'
              )?_c('v-text-field',{attrs:{"filled":"","rounded":"","label":_vm.veranstaltung.data.art == 'Auswärtsspiel'
                  ? 'Gegner'
                  : 'Gastmannschaft'},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.veranstaltung.data.gastmannschaft),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data, "gastmannschaft", $$v)},expression:"veranstaltung.data.gastmannschaft"}}):_vm._e(),(!_vm.update_serie)?_c('v-dialog',{ref:"beginndatum",attrs:{"return-value":_vm.veranstaltung.data.beginn.datum,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.veranstaltung.data.beginn, "datum", $event)},"update:return-value":function($event){return _vm.$set(_vm.veranstaltung.data.beginn, "datum", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.veranstaltung.data.beginn.datum),"label":"Datum","filled":"","rounded":"","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,3389962375),model:{value:(_vm.beginndatum),callback:function ($$v) {_vm.beginndatum=$$v},expression:"beginndatum"}},[_c('v-date-picker',{attrs:{"locale":"de","first-day-of-week":"1","scrollable":""},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.veranstaltung.data.beginn.datum),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data.beginn, "datum", $$v)},expression:"veranstaltung.data.beginn.datum"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.beginndatum = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.$refs.beginndatum.save(_vm.veranstaltung.data.beginn.datum)
                    _vm.update()}}},[_vm._v(" OK ")])],1)],1):_vm._e(),_c('v-dialog',{ref:"beginnuhrzeit",staticClass:"mb-4",attrs:{"return-value":_vm.veranstaltung.data.beginn.uhrzeit,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.veranstaltung.data.beginn, "uhrzeit", $event)},"update:return-value":function($event){return _vm.$set(_vm.veranstaltung.data.beginn, "uhrzeit", $event)},"change":function($event){return _vm.update()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Beginn","filled":"","rounded":"","prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.veranstaltung.data.beginn.uhrzeit),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data.beginn, "uhrzeit", $$v)},expression:"veranstaltung.data.beginn.uhrzeit"}},'v-text-field',attrs,false),on))]}}],null,false,825780938),model:{value:(_vm.beginnuhrzeit),callback:function ($$v) {_vm.beginnuhrzeit=$$v},expression:"beginnuhrzeit"}},[_c('v-time-picker',{attrs:{"format":"24hr","scrollable":""},model:{value:(_vm.veranstaltung.data.beginn.uhrzeit),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data.beginn, "uhrzeit", $$v)},expression:"veranstaltung.data.beginn.uhrzeit"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.beginnuhrzeit = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.$refs.beginnuhrzeit.save(
                      _vm.veranstaltung.data.beginn.uhrzeit
                    )
                    _vm.update()}}},[_vm._v(" OK ")])],1)],1),(!_vm.update_serie && _vm.veranstaltung.data.art == 'Versammlung')?_c('v-dialog',{ref:"endedatum",attrs:{"return-value":_vm.veranstaltung.data.ende.datum,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.veranstaltung.data.ende, "datum", $event)},"update:return-value":function($event){return _vm.$set(_vm.veranstaltung.data.ende, "datum", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.veranstaltung.data.ende.datum),"label":"Datum","filled":"","rounded":"","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,4213728612),model:{value:(_vm.endedatum),callback:function ($$v) {_vm.endedatum=$$v},expression:"endedatum"}},[_c('v-date-picker',{attrs:{"locale":"de","first-day-of-week":"1","scrollable":""},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.veranstaltung.data.ende.datum),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data.ende, "datum", $$v)},expression:"veranstaltung.data.ende.datum"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.beginndatum = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.$refs.beginndatum.save(_vm.veranstaltung.data.ende.datum)
                    _vm.update()}}},[_vm._v(" OK ")])],1)],1):_vm._e(),_c('v-dialog',{ref:"endeuhrzeit",attrs:{"return-value":_vm.veranstaltung.data.ende.uhrzeit,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.veranstaltung.data.ende, "uhrzeit", $event)},"update:return-value":function($event){return _vm.$set(_vm.veranstaltung.data.ende, "uhrzeit", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ende","filled":"","rounded":"","prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.veranstaltung.data.ende.uhrzeit),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data.ende, "uhrzeit", $$v)},expression:"veranstaltung.data.ende.uhrzeit"}},'v-text-field',attrs,false),on))]}}],null,false,1665526474),model:{value:(_vm.endeuhrzeit),callback:function ($$v) {_vm.endeuhrzeit=$$v},expression:"endeuhrzeit"}},[_c('v-time-picker',{attrs:{"format":"24hr","scrollable":""},model:{value:(_vm.veranstaltung.data.ende.uhrzeit),callback:function ($$v) {_vm.$set(_vm.veranstaltung.data.ende, "uhrzeit", $$v)},expression:"veranstaltung.data.ende.uhrzeit"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.endeuhrzeit = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.$refs.endeuhrzeit.save(_vm.veranstaltung.data.ende.uhrzeit)
                    _vm.update()}}},[_vm._v(" OK ")])],1)],1)],1),(!_vm.update_serie)?_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"color":_vm.template.colors.primary,"block":"","rounded":"","large":"","outlined":!_vm.start_delete},on:{"click":function($event){_vm.start_delete ? _vm.delVeranstaltung() : _vm.del()}}},[_vm._v(" "+_vm._s(_vm.start_delete ? 'Entfernen bestätigen' : 'Veranstaltung entfernen')+" ")])],1):_vm._e(),(_vm.start_delete)?_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"color":_vm.template.colors.primary,"block":"","rounded":"","large":"","outlined":""},on:{"click":function($event){_vm.start_delete = false
                _vm.start_serien_delete = false}}},[_vm._v(" Entfernen abbrechen ")])],1):_vm._e(),(_vm.veranstaltung.data.repeat && _vm.update_serie)?_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"color":_vm.template.colors.primary,"block":"","rounded":"","large":"","outlined":!_vm.start_serien_delete},on:{"click":function($event){_vm.start_serien_delete
                  ? _vm.delSerienVeranstaltung()
                  : (_vm.start_serien_delete = true)}}},[_vm._v(" "+_vm._s(_vm.start_serien_delete ? 'Entfernen bestätigen' : 'Serientermin löschen')+" ")]),(_vm.start_serien_delete)?_c('span',{staticClass:"mt-2"},[_vm._v(" Es werden ALLE zukünftigen Veranstaltungen dieses Serientermins unwiderruflich gelöscht. ")]):_vm._e()],1):_vm._e(),(_vm.start_serien_delete)?_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"color":_vm.template.colors.primary,"block":"","rounded":"","large":"","outlined":""},on:{"click":function($event){_vm.start_serien_delete = false}}},[_vm._v(" Entfernen abbrechen ")])],1):_vm._e()],1)],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"app":"","timeout":"10000","multi-line":"","color":"success","centered":"","rounded":"rounded-xl","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":!_vm.template.light,"icon":""},on:{"click":function($event){_vm.saved = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.saved),callback:function ($$v) {_vm.saved=$$v},expression:"saved"}},[_c('h2',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v("mdi-calendar-sync")]),_vm._v(" "+_vm._s(_vm.update_serie ? 'Serientermin gespeichert' : 'Veranstaltung gespeichert')+" ")],1),(_vm.update_serie)?_c('span',[_vm._v(" Alle zukünftigen Veranstaltungen dieses Serientermins wurden aktualisiert. ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }